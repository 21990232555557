import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import ArbitratorDetail from '@/pages/arbitratorManage/ArbitratorDetail/Index'
import style from './ArbitratorDetail.module.less'
import qs from 'querystring'

function Main () {
  const { id } = useParams()
  const bar = useMemo(() => {
    const s = qs.parse(location.search.replace('?', ''))
    return !s.bar
  }, [])
  return (
    <div className={style.container}>
      <ArbitratorDetail meta={{ id, isOut: true, bar }} />
    </div>
  )
}

export default Main

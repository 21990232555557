import React, { } from 'react'
import style from './Index.module.less'
import { Form, Row, Col } from 'antd'
import Text from '@/components/Text'
import Icon1 from '@/assets/img/arbitrator-info-icon1.png'
import Icon2 from '@/assets/img/arbitrator-info-icon2.png'
import Icon3 from '@/assets/img/arbitrator-info-icon3.png'
import Icon4 from '@/assets/img/arbitrator-info-icon4.png'
import Icon5 from '@/assets/img/arbitrator-info-icon5.png'

const { Item } = Form

export function initFormData (data, result) {
  const keys = ['name', 'license_type', 'license', 'gender', 'native_place', 'country', 'address', 'nation', 'academic_title', 'political', 'language', 'retire', 'iscivilservant', 'structure']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
  if (data.province && data.city) {
    result.provinceCity = `${data.province} ${data.city}`
  }
}

export function formatUpdateData (d) {

}

function Main () {
  return (
    <div>
      <div className={style.title}>
        <Item name='name' noStyle><Text format={v => v || '无'} /></Item>
      </div>
      <div className={style['other-info-line']}>
        <Item name='gender' noStyle>
          <Text format={v => v ? <span className={style.text}>{v}</span> : ''} />
        </Item>
        <Item name='nation' noStyle>
          <Text format={v => v ? <span className={style.text}>{v}</span> : ''} />
        </Item>
        <Item name='political' noStyle>
          <Text format={v => v ? <span className={style.text}>{v}</span> : ''} />
        </Item>
        <Item name='native_place' noStyle>
          <Text format={v => v ? <span className={style.text}>{v}</span> : ''} />
        </Item>
      </div>
      <Row gutter={24}>
        <Col span={8}>
          <div className={style.cell}>
            <img src={Icon1} className={style['cell-icon']} />
            <span className={style['cell-title']}>居住地</span>
            <span className={style.split}>-</span>
            <Item noStyle name='address'>
              <Text format={v => v || '无'} />
            </Item>
          </div>
        </Col>
        <Col span={8}>
          <div className={style.cell}>
            <img src={Icon2} className={style['cell-icon']} />
            <Item noStyle name='license_type'>
              <Text format={v => v || '证件号'} />
            </Item>
            <span className={style.split}>-</span>
            <Item noStyle name='license'>
              <Text format={v => v || '无'} />
            </Item>
          </div>
        </Col>
        <Col span={8}>
          <div className={style.cell}>
            <img src={Icon3} className={style['cell-icon']} />
            <Item noStyle name='retire'>
              <Text format={v => v === 1 ? '非在职' : '在职'} />
            </Item>
            <Item noStyle name='iscivilservant'>
              <Text format={v => v === 1 ? <span><span className={style.split}>-</span>公务员</span> : ''} />
            </Item>
          </div>
        </Col>
        <Col span={8}>
          <div className={style.cell}>
            <img src={Icon4} className={style['cell-icon']} />
            <span className={style['cell-title']}>职称</span>
            <span className={style.split}>-</span>
            <Item noStyle name='academic_title'>
              <Text format={v => v || '无'} />
            </Item>
          </div>
        </Col>
        <Col span={8}>
          <div className={style.cell}>
            <img src={Icon5} className={style['cell-icon']} />
            <span className={style['cell-title']}>语言</span>
            <span className={style.split}>-</span>
            <Item noStyle name='language'>
              <Text format={v => v || '无'} />
            </Item>
          </div>
        </Col>
        <Col span={8}>
          <div className={style.cell}>
            <img src={Icon1} className={style['cell-icon']} />
            <span className={style['cell-title']}>常住地址</span>
            <span className={style.split}>-</span>
            <Item noStyle name='provinceCity'>
              <Text format={v => v || '无'} />
            </Item>
          </div>
        </Col>
        <Col span={8}>
          <div className={style.cell}>
            <img src={Icon5} className={style['cell-icon']} />
            <span className={style['cell-title']}>国家</span>
            <span className={style.split}>-</span>
            <Item noStyle name='country'>
              <Text format={v => v || '无'} />
            </Item>
          </div>
        </Col>
        <Col span={8}>
          <div className={style.cell}>
            <img src={Icon1} className={style['cell-icon']} />
            <span className={style['cell-title']}>单位</span>
            <span className={style.split}>-</span>
            <Item noStyle name='structure'>
              <Text format={v => v || '无'} />
            </Item>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Main
